.wrap__main {
  font-family: var(--ML);
  max-width: 100vw;
  min-height: 100vh;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  color: var(--color-black);
  position: relative;
  overflow: hidden;
}

.container {
  width: 100%;
  max-width: 160rem;
  margin: 0 auto;
  padding: 0 4rem;
}

.section__button {
  display: block;
  padding: 1.6rem 6.025rem;
  width: max-content;
  border-radius: 20rem;
  color: var(--color-white);
  text-align: center;
  font: 500 1.8rem / 2.4rem var(--ML);
  white-space: nowrap;
  position: relative;
  text-transform: uppercase;
  transition: 0.2s all ease;
  &:after{
    content: '';
    position: absolute;
    border: 0.1rem solid var(--color-white);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    border-radius: 20rem;
    transition: 0.2s all ease;
  }
  &:hover {
    transition: 0.2s all ease;
    color:  #F18606;
    &:after{
      transition: 0.2s all ease;
      border-color:  #F18606;
    }
  }
  &:active {
    transition: 0.2s all ease;
    background: #4F3F8D;
    color:#F18606;
    &:after{
      transition: 0.2s all ease;
      border-color:  #F18606;
    }
  }
}


.header{
  padding: 2rem 0;
  background:var(--color-header);
  &__block{
    display: flex;
    gap:2rem;
    align-items: center;
    justify-content: space-between;
  }
  &__logo{
    width: 15.353rem;
  }
}
.main{
  color: var(--color-white);
  background: var(--color-main-gradient);
  padding: 8.9rem 0 25.9rem;
  &__flex{
    display: flex;
    gap:6.7rem;
  }
  &__info{
    width: 46%;
    display: flex;
    flex-direction: column;
  }
  &__gallery{
    width: calc(54% - 6.7rem);
  }
  &__title{
    font: 700 6rem/7.2rem var(--ML);
    margin-bottom: 2.4rem;
    max-width: 61.5rem;
  }
  &__subtitle{
    font: 400 2.4rem/3.2rem var(--ML);
    margin-bottom: 5.6rem;
    max-width: 61.5rem;
  }
  &__btn{
    display: flex;
    gap: 2.4rem;
    margin-bottom: 5.6rem;
  }
  &__button{
    color: var(--color-black);
    background: var(--color-orange);
    transition: 0.2s all ease;
    &:after{
      transition: 0.2s all ease;
      display: none;
    }
    &:hover{
      color: var(--color-black);
      transition: 0.2s all ease;
      background: linear-gradient(90deg, #F18606 0%, #EB5D12 100%);
    }
    &:active{
      color: var(--color-black);
      transition: 0.2s all ease;
      background: #EB5D12;
    }

  }
  &__counter{
    flex-grow: 1;
    display: flex;
    gap:3rem;
    justify-content: space-between;
    margin-left: 2rem;
    &-item{
      //width: calc((100% - 6rem)/3);
      display: flex;
      flex-direction: column;
      gap:0.4rem;
      align-items: center;
      text-align: center;
      position: relative;
      justify-content: flex-end;

      &:not(:first-child):after{
        content: '';
        position: absolute;
        height: 10.4rem;
        left: -8.5rem;
        width: 0.1rem;
        bottom: 0rem;
        background: var(--color-white);
        //transform: translateY(-50%);
      }
      h3 {
        font: 500 6rem/7rem var(--ML);
        color: transparent;
        -webkit-text-stroke: 0.2rem var(--color-white);
      }
      p{
        font: 400 2rem/3rem var(--ML);
        text-transform: uppercase;
      }
    }
  }
  &__gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 3.2rem;
  }
  .gallery__item{
    width: calc(50% - 1.6rem);
    max-height: 27.2rem;
    height: 100%;
    border-radius:  1.6rem;
    position: relative;
    img{
      border-radius:  1.6rem;
    }
    &:nth-child(1){
      width: 32%;
      max-height: 38.8rem;
      background: #F09B36;
      border-radius: 1.6rem;

      img{
        border-radius: 0;
        position: absolute;
        width: 120%;
        height: 120%;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &:nth-child(2){
      max-height: 38.8rem;
      width: calc(68% - 3.2rem);
      &:before{
        content: '';
        pointer-events: none;
        position: absolute;
        bottom: -3.5rem;
        left: 4rem;
        width: 11.9rem;
        height: 11.9rem;
        background: url(../img/schedule.png) center/11.9rem 11.9rem no-repeat;
      }
    }
  }
}
.client{
  padding: 0 0 7.5rem;
  &__list{
    display: flex;
    gap:3.2rem;
    margin: -10.5rem 0 6.4rem;
  }
  &__item{
    border-radius: 2.4rem;
    padding: 4rem;
    display: flex;
    gap:2.4rem;
    flex-direction: column;
    color:var(--color-header);
    background: var(--color-violet-light);
    width: calc((100% - 6.4rem)/3);
    &:first-child{
      background: var(--color-violet);
    }
  }
  &__title{
    font: 400 2.4rem/3.2rem var(--ML);
  }
  &__flex{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    h3{
      font: 500 5.6rem/6.4rem var(--ML);
    }
  }
  &__icon{
    width: 6.4rem;
    height: 6.4rem;
  }
  &__info{
    display: flex;
    flex-wrap: wrap;
    gap:3.2rem;
    &-item{

      display: flex;
      flex-direction: column;
      justify-content: center;
      padding : 2.4rem 0 2.4rem 4rem ;
      gap:2.4rem;
      border-left:0.1rem solid var(--color-header) ;
      width: calc((100% - 6.4rem)/3);
    }
    &-banner{
      border:none;
      padding:0 ;
      width: calc((100% - 3.2rem)/3*2);
    }
    &-title{
      color:#000;
      font: 400 2.4rem/3.2rem var(--ML);
      span{
        display: block;
        margin-top: 0.8rem;
        font: 400 1.6rem/2.4rem var(--ML);
      }
    }
    &-count{
      font: 500 5.6rem/6.4rem var(--ML);
    }
  }
  &__column{
    display: flex;
    justify-content: center;
    gap:3.5rem;
    margin: 6.4rem 6.3rem 0;
    &-item{
      width: calc((100% - 3.5rem)/2);
      border: 0.1rem solid var(--color-header);
      padding: 4rem;
      border-radius: 2.4rem;
      display: flex;
      flex-direction: column;
      gap:4.8rem;
    }
    &-row{
      display: flex;
      justify-content: space-between;
      gap:2rem;
      position: relative;
      align-items: center;
      h4{
        font: 400 2.4rem/3.2rem var(--ML);
      }
      span{
        white-space: nowrap;
        font: 700 2.4rem/3.2rem var(--ML);
      }
      &:not(:last-child):after{
        content: '';
        position: absolute;
        bottom: -2.4rem;
        left: 0;
        height: 0.1rem;
        width: 100%;
        background: var(--color-header);
      }
    }
  }
}
.supervisors{
  position: relative;
  padding: 7.5rem 0;
  &__title{
    font: 700 5.6rem/7.2rem var(--ML);

  }
  &__list{
    display: flex;
    align-items: stretch;
  }
  &__item{
    display: flex;
    border-radius: 2.4rem;
    background: var(--color-violet-light);
    align-items: center;
    height: auto;
  }
  &__img{
    width: 42%;
    min-width: 42%;
    border-radius: 2.4rem;
    overflow: hidden;
    height: 100%;
  }
  &__info{
    width: 58%;
    padding: 2.4rem ;
    flex-grow: 1;
  }
  &__name{
    font: 500 3.2rem/4rem var(--ML);
    margin-bottom: 0.8rem;
  }
  &__position{
    font: 500 2rem/2.4rem var(--ML);
    color:#696969;
    margin-bottom: 2.4rem;
  }
  &__text{
    font: 400 1.6rem/2.4rem var(--ML);
  }
  &__nav{
    display: flex;
    gap:3.2rem;
    align-items: center;
  }
  &__top{
    display: flex;
    justify-content: space-between;
    gap:3rem;
    align-items: center;
    margin-bottom: 4rem;
  }
  &__pagination{
    position: relative;
    margin-top:5.6rem ;
  }
}

.countries{
  padding: 7.5rem 0 20rem;
  position: relative;

  &__list{
    display: flex;
    justify-content: space-between;
    gap:4rem;

    margin-top: 14.3rem;
  }
  &__back{
    position: absolute;
    width: 100%;
    top:24%;
    max-width: 198rem;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);

  }
  &__boy{
    position: absolute;
    width: auto;
    height: 19rem;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
  }
  &__item{
    position: relative;
    max-width: 42rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    transition: 0.2s all ease;
    &:hover{
      .countries__button{
        transition: 0.2s all ease;
        color:#EB5D12;
        &:before{
          background: url(../img/arr-orange.svg) center/3.2rem 1.4rem no-repeat;      }
        &:after{
          border-color:#EB5D12
        }
      }
    }
    &:first-child{
      &:hover .countries__map{
        path{
          transition: 0.2s all ease;
          fill:#FFC300;
        }
      }
    }
    &:nth-child(2){
      &:hover .countries__map{
        path{
          transition: 0.2s all ease;
          fill:#00C3E1;
        }
      }
    }
    &:nth-child(3){
      &:hover .countries__map{
        path{
          transition: 0.2s all ease;
          fill:#D92F00;
        }
      }
    }
  }
  &__map{
    margin: 0 auto;
    width: auto;
    height: 22.4rem;
    svg{
      width: 100%!important;
      height: 100%!important;
      margin: 0 auto  ;
      pointer-events: none;
    }

  }
  &__img{
    pointer-events: none;
    transition: 0.2s all ease;
  }
  &__button{
    display: flex;
    gap:3.2rem;
    background: white;
    padding: 1rem 8rem 1rem 1rem;
    align-items: center;
    position: relative;
    border-radius: 10rem;
    transition: 0.2s all ease;
    margin-top: 4rem;
    img{
      width: 6.4rem;
      min-width: 6.4rem;
      height: 6.4rem;
      border-radius: 50%;
    }
    span{
      font: 400 2.4rem/3.2rem var(--ML);
      text-transform: uppercase;
    }
    &:after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border:0.1rem solid var(--color-header);
      border-radius: 10rem;
      pointer-events: none;
    }

    &:before{
      content: '';
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 4rem;
      width: 3.2rem;
      height: 1.4rem;
      background: url(../img/arr-small.svg) center/3.2rem 1.4rem no-repeat;
    }
    &:hover {
      transition: 0.2s all ease;
      color:#EB5D12;
      &:before{
        background: url(../img/arr-orange.svg) center/3.2rem 1.4rem no-repeat;      }
      &:after{
        border-color:#EB5D12
      }
    }
    &:active {
      background: var(--color-header);
      color:white!important;
      span{
        color:white;
      }
      transition: 0.2s all ease;
      &:before{
        filter: brightness(0) invert(1);
      }
      &:after{
        border:0.1rem solid var(--color-header)!important;
      }
    }
  }
}
.swiper-button-next, .swiper-button-prev{
  position: relative;

  left: unset;
  right: unset;
  margin: 0 ;
  top: 0;
  width: 6.4rem;
  height: 4.4rem;
  transition: 0.2s all ease;
  &:after{
    display: none;
  }
  &:before{
    content: '';
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 6.4rem;
    height: 4.4rem;
    background: url(../img/arr.svg) center/6.4rem 4.4rem no-repeat;
  }
  &:active{
    transition: 0.2s all ease;
  }
  &:active:before{
      background: url(../img/arr-big-orange.svg) center/6.4rem 4.4rem no-repeat;

  }
}
.swiper-button-next:before{
  transform: translateY(-50%) rotate(180deg);
}
.supervisors__pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 40rem;
  bottom: 0;
  margin: 5.6rem  auto 0;
  width: 100%;
  max-width: 40rem;
}

.swiper-pagination-bullet {
  height: 0.4rem; /* Висота bullets */
  background: #B9B9B9;
  border-radius: 0.2rem;
  transition: background 0.3s ease, width 0.3s ease;
  flex-shrink: 0;
  margin: 0!important;
}

.swiper-pagination-bullet-active {
  background: var(--color-header);
}

.footer{
  padding: 3.3rem 0;
  background: var(--color-violet-light);
  &__block{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:3rem;
  }
  &__policy{
    display: flex;
    flex-direction: column;
    gap:1.2rem;
    font: 400 1.6rem/2.4rem var(--ML);
    a{
      width: max-content;
      border-bottom: 0.1rem solid var(--color-black);
      transition: 0.2s all ease;
      &:hover{
        transition: 0.2s all ease;
        filter: invert(54%) sepia(57%) saturate(2200%) hue-rotate(2deg) brightness(99%) contrast(95%);
      }
    }
  }
  &__social{
    display:flex;
    gap:2.4rem;
    align-items: center;
    .img{
      width: 3.2rem;
      height: 3.2rem;
      display: block;
      transition: 0.2s all ease;
      &:hover{
        transition: 0.2s all ease;
        filter: invert(54%) sepia(57%) saturate(2200%) hue-rotate(2deg) brightness(99%) contrast(95%);
      }
      &:active{
        transition: 0.2s all ease;
        filter: invert(51%) sepia(55%) saturate(5085%) hue-rotate(355deg) brightness(95%) contrast(94%);
      }
    }
  }
}


@media only screen and (max-width: 666px) {
  .header{
    padding: 1.6rem;
  }
  .header .section__button{
    display: none;
  }
  .header__logo{
    width: 10.2rem;
    margin: 0 auto ;
  }
  .main__flex{
    flex-direction: column;
    & > *{
      width: 100%;
    }
  }
  .main__gallery{
    display: none;
  }

  .container{
    padding: 0 1.6rem;
  }
  .main{
    padding-top: 3.2rem;
    padding-bottom: 12.7rem;
  }
  .main__title{
    font-size: 4rem;
    line-height: 4.8rem;
    max-width: 100%;
  }
  .main__subtitle{
    font-size: 2rem;
    line-height: 2.8rem;
    max-width: 100%;
    margin-bottom: 2.4rem;
  }
  .main__btn{
    gap:1.6rem;
    flex-direction: column;
    margin-bottom: 2.4rem;
  }
  .section__button{
    max-width: 34.3rem;
    width: 100%;
    font-size: 1.6rem;
    padding: 1.2rem;
  }
  .main__counter{
    margin: 5.6rem 0 0;
    gap:2rem;
    align-items: flex-start;

  }
  .main__counter-item h3{
    font-size: 4rem;
    line-height: 4.8rem;
  }
  .main__counter-item p{
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  .main__counter-item{
    width:calc((100% - 4rem)/3) ;
  }
  .main__counter-item:not(:first-child):after{
    height: 10rem;
    bottom: unset;
    top:0;
    left: -1rem;
  }
  .client__list{
    gap:1.6rem;
    margin: -7rem 0 1rem ;
    flex-direction: column;
  }
  .client__item{
    width: 100%;
    padding: 2.4rem;
    gap:1.6rem;
    border-radius: 1.6rem;
  }
  .client__title{
    font-size: 2rem;
    line-height: 2.8rem;
  }
  .client__icon{
    width: 4.8rem;
    height: 4.8rem;
  }
  .client__flex h3{
    font-size: 3.6rem;
    line-height: 4rem;
  }
  .client__info{
    gap:2.4rem;
  }
  .client__info-item{
    width: 100%;
  }
  .client__info-item{
    padding: 1rem 1rem 1rem 2.4rem;
    gap:1.6rem;
  }
  .client__info-title{
    font-size: 2rem;
    line-height: 2.8rem;
  }
  .client__info-count{
    font-size:3.6rem;
    line-height: 4rem;
  }
  .client__column{
    margin: 4rem 0 0;
    flex-direction: column;
    gap:1.6rem;
    & > *{
      width: 100%;
    }
  }
  .client__column-item{
    padding: 2.4rem;
    gap:3.2rem;
  }
  .client__column-row h4, .client__column-row span{
    font-size:2rem;
    line-height: 2.8rem;
  }
  .client__column-row:not(:last-child):after{
    bottom: -1.6rem;
  }
  .client{
    padding-bottom: 3.6rem;
  }
  .supervisors{
    padding: 3.6rem 0;
  }
  .supervisors__title{
    font-size: 3.6rem;
    line-height: 4rem;
  }
  .supervisors__item{
    flex-direction: column;
    &>*{
      width: 100%;
    }
  }
  .supervisors__img{
   border-radius: 1.6rem;
  }
  .supervisors__info{
    padding: 1.6rem;
  }
  .supervisors__name{
    font-size: 2.4rem;
    line-height: 3.2rem;
    margin-bottom: 0.4rem;
  }
  .supervisors__position{
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
  .main .gallery__item{
    width: 100%;
    max-height: 53vw;
    height: 53vw;
  }
  .supervisors__pagination{
    margin: 0;
    bottom: unset!important;
  }
  .supervisors__top > .supervisors__nav{
    display: none;
  }
  .supervisors__top{
    margin-bottom: 2.4rem;
  }
  .supervisors__nav{
    justify-content: space-between;
    align-items: center;
    margin: 3.6rem 2rem 0;
  }
  .supervisors__img{
    height: 91.5vw;
  }
  .swiper-button-next, .swiper-button-prev{
    width: 4.4rem;
    height: 1.6rem;
  }
  .swiper-button-next:before, .swiper-button-prev:before{
    width: 4.4rem;
    height: 1.6rem;
    background: url(../img/arr.svg) center / 4.4rem 1.6rem no-repeat;
  }
  .swiper-pagination-bullet{
    height: 0.3rem;
  }
  .swiper-button-next:before, .swiper-button-prev:before{
    width: 4.4rem;
    height: 1.6rem;
    background: url(../img/arr.svg) center / 4.4rem 1.6rem no-repeat;
  }
  .swiper-button-next, .swiper-button-prev{

    &:active:before{
      width: 4.4rem;
      height: 1.6rem;
      background: url(../img/arr-big-orange.svg) center / 4.4rem 1.6rem no-repeat;

    }
  }
  .countries{
    padding: 3.2rem 0 7.2rem;
  }
  .countries__list{
    margin-top: 4rem;
    flex-direction: column;
    gap:6.4rem;
  }
  .countries__map{
    height: 19rem;
  }
  .countries__button img{
    width: 4rem;
    height: 4rem;
    min-width: 4rem;
  }
  .countries__button{
    margin: 1.6rem auto 0 ;
    gap:2.4rem;
    max-width: 34.3rem;
    width: 100%;
  }
  .countries__button span{
    font-size: 2rem;
    line-height: 2.8rem;
  }
  .countries__item{
    max-width: 100%;
  }
  .client__info-banner{
    padding: 0;
    margin-bottom: 1.6rem;
  }
  .client__column-row{
    gap:1rem;
  }
  .countries__back{
    top:17%;
  }
  .footer__block{
    flex-direction: column-reverse;
    align-items: flex-start;
    gap:2.4rem;
  }
  .footer{
    padding: 3.2rem 0;
  }
  .footer__social{
    gap:3.2rem;
  }
  .main__title{
    position: relative;
  }
  .main__title:before{
    content: '';
    pointer-events: none;
    position: absolute;
    top: 3.5rem;
    right: -1.5rem;
    width: 8.9rem;
    height: 8.9rem;
    background: url(../img/schedule.png) center/8.9rem 8.9rem no-repeat;
  }
}